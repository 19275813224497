import React from "react";
import {
  Box,
  Stack,
  FormControl,
  Button,
  InputAdornment,
  TextField,
  CircularProgress,
} from "@mui/material";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import HttpsOutlinedIcon from "@mui/icons-material/HttpsOutlined";
import { Link } from "react-router-dom";
import { iconSize, inputFontSize, inputHeight } from "../../constants/Constant";

export default function LoginForm({
  register,
  handleSubmit,
  onSubmit,
  status,
  formState: { errors },
}) {
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Box
          width={{ xs: "92%", sm: "100%" }}
          bgcolor="white"
          color="#0f0e58"
          borderRadius={2}
        >
          <Box
            width={{ xs: "90%", sm: "80%" }}
            mx={"auto"}
            py={{ xs: 4, sm: 8 }}
            px={{ xs: 2, sm: 2 }}
          >
            <Stack spacing={4}>
              <FormControl fullWidth > 

                <TextField
                  label="Phone"
                  variant="outlined"
                  placeholder="Enter Your Phone "
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" sx={{ mx: 1 }}>
                        <LocalPhoneOutlinedIcon sx={{ fontSize: iconSize }} />
                      </InputAdornment>
                    ),
                  }}
                  {...register("phone", { required: "Phone Required" })}
                  {...(errors.phone ? { error: true } : {})}
                  helperText={errors.phone ? errors.phone.message : null}
                  type="tel"
                  sx={{ fontSize: inputFontSize,height:inputHeight}}
                />
              </FormControl>
              <FormControl fullWidth > 
                <TextField
                  label="Password"
                  variant="outlined"
                  placeholder="Enter Your Password"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" sx={{ mx: 1 }}>
                        <HttpsOutlinedIcon sx={{ fontSize: iconSize }} />
                      </InputAdornment>
                    ),
                  }}
                  {...register("password", { required: "Password Required" })}
                  {...(errors.password
                    ? { error: true, helperText: errors.password.message }
                    : {})}
                  type="password"
                  sx={{ fontSize: inputFontSize,height:inputHeight }}
                />
              </FormControl>

              <Button
                type="submit"
                variant="contained"
                size="large"
                fullWidth
                sx={{
                  backgroundColor: "#0f0e58",
                  ":hover": { backgroundColor: "#0f0e78" },
                }}
                disabled={status.loading}
              >
                {status.loading ? (
                  <CircularProgress sx={{ color: "#0f0e58" }} size={25} />
                ) : (
                  "Login"
                )}
              </Button>
              <Stack
                direction={"row"}
                justifyContent={{ xs: "space-around", sm: "start" }}
                spacing={2}
                alignItems={"center"}
              >
                <Link
                  to="/register"
                  style={{
                    textDecoration: "none",
                    color: "#0f0e58",
                    pointerEvents: status.loading ? "none" : "auto",
                    fontFamily: "Palanquin",
                  }}
                >
                  Create an Account
                </Link>
                <Link
                  to="/forgot-password"
                  style={{
                    textDecoration: "none",
                    color: "#0f0e58",
                    pointerEvents: status.loading ? "none" : "auto",
                    fontFamily: "Palanquin",
                  }}
                >
                  Forgot password
                </Link>
              </Stack>
            </Stack>
          </Box>
        </Box>
      </Box>
    </form>
  );
}
