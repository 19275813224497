import React, { useContext, useEffect, useState } from "react";
import {
  RouterProvider,
} from "react-router-dom";
import { createBrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@emotion/react";
import { theme } from "./constants/Theme";
import { AuthContext } from "./contexts/AuthContext";
import Loading from "./components/Loading";
import { redirectUser } from "./services/Redirect";
import NotFound from "./pages/NotFound";
import routes from "./constants/Routes";


const router = createBrowserRouter(routes);

function App() {
  const { user, status} = useContext(AuthContext);
  const [redirectPath, setRedirectPath] = useState(null);

  useEffect(() => {
    const fetchRedirectPath = async () => {
      const path = await redirectUser({ user });
      setRedirectPath(path);
    };

    fetchRedirectPath();
  }, [user]);

  useEffect(() => {
    if (user.isAuth && redirectPath) {
      router.navigate(`/${redirectPath}`);
    }
  }, [redirectPath, user.isAuth]);
 

  return (
    <ThemeProvider theme={theme}>
      {status.pageLoading ? (
        <Loading />
      ) : (
        <RouterProvider
          router={router}
          fallbackElement={<NotFound />}
        ></RouterProvider>
      )}
    </ThemeProvider>
  );
}

export default App;
