const redirectUser = async ({ user }) => {
  let path = null;
  if(user.isAuth){
  if (user.phone_verified_at === null) {
    path = "verify-phone";
  } else if (!user.studentInfo) {
    path = "verify-id";
  }  else if (!user.id_uploaded) {
    path = "upload-id";
  } else if (!user.is_verified) {
    path = "under-review";
  }else if(user.studentInfo.is_verified){
    path="profile"
  }

}
  return path;
};

export { redirectUser };
