import React from "react";
import {
  Box,
  Typography,
  Stack,
  FormControl,
  Button,
  CircularProgress,
} from "@mui/material";
import {
  CloudUpload,
} from "@mui/icons-material";

export default function UploadIDForm({ selectedImage,setSelectedImage,imageErrors,setImageErrors ,status,onSubmit,handleImageChange}) {


  
  
  return (
    <form onSubmit={onSubmit}>
      <Box width={{ display: "flex", justifyContent: "center" }}>
        <Box width={{ xs: "92%", sm: "100%" }} bgcolor="white" color="#1c2145"  borderRadius={2}>
          <Box width={{ xs: "90%", sm: "80%" }} mx={"auto"} py={{xs:4,sm:8}} px={{xs:2,sm:2}}>
          <Stack spacing={4}>
            <Typography align="center" variant="h5" sx={{ fontWeight: "bold" }}>
              Verify Your ID
            </Typography>

            <Typography align="center" variant="subtitle1" >
              Upload Student ID Card
            </Typography>

            <FormControl fullWidth sx={{height:170}} > 
           
  <Box
    position="relative"
    height={160}
    width={"100%"}
    borderRadius={3}
    display="flex"
    alignItems="center"
    cursor="pointer"
    justifyContent="center"
    border="1px solid"
    borderColor={imageErrors.length > 0 ? "red" : "#c8584a9cc"}
    bgcolor="#e7e7ee"
    style={{ cursor: "pointer" }}
  >
    <input
      type="file"
      name="file_path"
      accept="image/*" // Specify accepted file types if needed
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        bottom: 0,
        width: "100%",
        height: "100%",
        opacity: 0,
        cursor: "pointer",
      }}
      onChange={handleImageChange}
    />
    {
      selectedImage?(
        <img
      src={URL.createObjectURL(selectedImage)}
      alt="Preview"
      style={{ maxWidth: "100%", height: "auto", maxHeight: 160, marginY: 4 }}

    />
      ):(
        <CloudUpload fontSize="large" />

      )
    }

  </Box>

  <Typography  variant="p" sx={{color:"red",fontSize:"12px",marginTop:"2px"}}> {imageErrors.map((error,index)=>{
               return <li key={index}> <span>{error}</span> <br /></li>

              })} </Typography>


             
            </FormControl>
         
            <Button
              type="submit"
              variant="contained"
              size="large"
              fullWidth
              sx={{
                backgroundColor: "#12c63f",
                ":hover": { backgroundColor: "#12c658" },
              }}
              disabled={ status.loading}
            >
            {status.loading ? (
              <CircularProgress sx={{color:"#0f0e58"}} size={25} />
            )   : (
                 <>Upload</>
                )}
            </Button>
          </Stack>
        </Box>
        </Box>
      </Box>
    </form>
  );
}
