import React, { useContext } from "react";
import {
  Box,
  Typography,
  Stack,
  FormControl,
  Button,
  InputAdornment,
  CircularProgress,
  TextField,
} from "@mui/material";
import { Link } from "react-router-dom";
import { Password } from "@mui/icons-material";
import { inputFontSize, inputHeight } from "../../constants/Constant";
import { AuthContext } from "../../contexts/AuthContext";

export default function VerifyPhoneForm({register,handleSubmit,onSubmit,onChangePhone,status,onResend,formState:{errors}}) {
 
const {user} =useContext(AuthContext);
  return (
   

        <form onSubmit={handleSubmit(onSubmit)}>
         <Box width={{ display: "flex", justifyContent: "center" }}>
        <Box width={{ xs: "92%", sm: "100%" }} bgcolor="white" color="#1c2145"  borderRadius={2}>
          <Box width={{ xs: "90%", sm: "80%" }} mx={"auto"} py={{xs:4,sm:8}} px={{xs:2,sm:2}}>
              <Stack spacing={4}>
                <Typography align="center" variant="h5" sx={{ fontWeight: "bold" }}>Verify Your Mobile Number</Typography>
              
                <Typography align="center" variant="subtitle1">
                  OTP has been sent to Your Mobile Number <br></br>
                  {user.phone} <Link style={{color:"#12c63f",fontWeight:"medium"}} onClick={onChangePhone}>Change Phone</Link>

                </Typography>
              
                <FormControl fullWidth >
                  <TextField
                    placeholder="OTP"
                    {...(errors.otp ? { error: true } : {})}
                    InputProps={{
                      startAdornment: <InputAdornment position="start"><Password/></InputAdornment>,
                    }}
                  
                    {...register("otp",{required:"OTP Required"})}
                    helperText={ errors.otp?errors.otp.message:null}

                    type="text"
                    sx={{ fontSize: inputFontSize ,height:inputHeight}}

                  />
                </FormControl>
                {/* {status.error && (<Alert severity="error">{status.error}.</Alert>)} */}

                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  fullWidth
                  sx={{
                    backgroundColor: "#12c63f",
                    ":hover": { backgroundColor: "#12c658" },
                  }}
                  disabled={status.verifying || status.resending}
                >
                {status.verifying ? (
                  <CircularProgress sx={{color:"#0f0e58"}} size={25} />
                ) : (
                  
                  "Verify")
                  }
                </Button>
                <Typography >
                  {status.resending?( <span> Resending...</span> ):(<Link onClick={onResend}
                  style={{
                    textDecoration: "none",
                    color: "#0f0e58",
                    pointerEvents: status.verifying ? "none" : "auto",
                    fontFamily: "Palanquin",
                  }}
                  >Resend OTP</Link>)}
                </Typography>
              </Stack>
            </Box>
            </Box>
          </Box>
        </form>
    
  );
}
