import React, { useContext, useEffect, useState } from "react";
import AppLayout from "../../components/layouts/AppLayout";
import VerifyIDForm from "../../components/auth/VerifyIDForm";
import { useForm } from "react-hook-form";
import { Box, Stack } from "@mui/material";
import { AuthContext } from "../../contexts/AuthContext";
import StudentInfoForm from "../../components/auth/StudentInfoForm";
import { baseUrl } from "../../constants/Constant";
import axios from "axios";
import AuthHeader from "../../components/auth/AuthHeader";

export default function VerifyID() {
  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    trigger,
    setError
  } = useForm();
  const [status, setStatus] = useState({ serverErrors: null, loading: false });
  const [universities, setUniversities] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [step, setStep] = useState(1);
  const [type, setType] = useState(1);
  const [date, setDate] = useState({ issued_at: null, expired_at: null });
  const { setUser } = useContext(AuthContext);

  const onSubmit = async (data) => {
    data.issued_at = date.issued_at;
    data.expired_at = date.expired_at;

    setStatus({ ...status, loading: true });

    try {
      const url = baseUrl + "student/register-info";
      const token = localStorage.getItem("token");
  
      const headers = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.post(url, data, headers );
      const userInfo=response.data.userInfo;
      setUser({...userInfo,isAuth:true});  
      
      setStatus({...status, loading: false})

    } catch (error) {
      const msg=error.response?.data.message;
      if(msg.id_no ){
        setError('id_no',{
          type: "manual",
          message: msg.id_no,
        });
      }
      if(msg.university_id ){
        setError('university_id',{
          type: "manual",
          message: msg.university_id,
        });
      }
      if(msg.study_field ){
        setError('study_field',{
          type: "manual",
          message: msg.study_field,
        });
      }
      setStatus({...status,serverErrors:error.response?.message, loading: false})
    }

  };

  useEffect(() => {
    if (step === 2) {
      const deptUrl = baseUrl + "departments";
      const universityUrl = baseUrl + "universities";

      try {
        axios
          .get(deptUrl)
          .then((response) => setDepartments(response.data.departments))
          .catch((error) =>
            console.error("Error fetching departments:", error)
          );

        axios
          .get(universityUrl)
          .then((response) => setUniversities(response.data.universities))
          .catch((error) =>
            console.error("Error fetching departments:", error)
          );
      } catch (err) {
        console.log(err);
      }
    }
  }, [step]);
  const onNext = () => {
    setStatus({ ...status, loading: true });
    setTimeout(() => {
      setStatus({ ...status, loading: false });
      setStep(step + 1);
    }, 2000);
  };

  return (
    <>
      <AppLayout>
        <Box width={{ xs: "100%", sm: 500 }}>
          <Stack direction="column" spacing={1}>
          <AuthHeader/>

            {step === 1 ? (
              <VerifyIDForm
                register={register}
                handleSubmit={handleSubmit}
                onNext={onNext}
                type={type}
                setType={setType}
                status={status}
              />
            ) : (
              <StudentInfoForm
                register={register}
                control={control}
                handleSubmit={handleSubmit}
                formState={{ errors }}
                onSubmit={onSubmit}
                status={status}
                departments={departments}
                universities={universities}
                date={date}
                setDate={setDate}
                setValue={setValue}
                trigger={trigger}
                type={type}
                
              />
            )}
          </Stack>
        </Box>
      </AppLayout>
    </>
  );
}
