import React from "react";
import AppLayout from "../../components/layouts/AppLayout";
import { Box, Stack } from "@mui/material";
import UnderReviewForm from "../../components/auth/UnderReviewForm";
import AuthHeader from "../../components/auth/AuthHeader";

export default function UnderReview() {

 

  return (
    <>
      <AppLayout>
        <Box width={{ xs: "100%", sm: 500 }}>
          <Stack direction="column" spacing={1}>
            <AuthHeader />

            <UnderReviewForm
            />
          </Stack>
        </Box>
      </AppLayout>
    </>
  );
}
