import React from 'react'
import AppLayout from '../../components/layouts/AppLayout'
import { Person2Rounded } from '@mui/icons-material'
import AuthHeader from '../../components/auth/AuthHeader'

export default function Profile() {
  return (
    <div>
<AppLayout>
<AuthHeader/>

  <Person2Rounded/> Profile Page
</AppLayout>
    </div>
  )
}
