import React from "react";
import {
  Box,
  Typography,
  Stack,
  Button,
  InputAdornment,
  MenuItem,
  TextField,
  CircularProgress,
  FormControl,
} from "@mui/material";

import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import HttpsOutlinedIcon from "@mui/icons-material/HttpsOutlined";
import { Link } from "react-router-dom";
import {
  PersonOutlineOutlined,
  Wc,
} from "@mui/icons-material";
import { iconSize, inputFontSize, inputHeight } from "../../constants/Constant";
import { Controller } from "react-hook-form";

export default function RegisterForm({
  register,
  handleSubmit,
  onSubmit,
  formState: { errors },
  status,
  trigger,
  setValue,
  control,
}) {
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box width={{ display: "flex", justifyContent: "center" }}>
        <Box
          width={{ xs: "92%", sm: "100%" }}
          bgcolor="white"
          color="#0f0e58"
          borderRadius={2}
        >
          <Box
            width={{ xs: "90%", sm: "80%" }}
            mx={"auto"}
            my={"auto"}
            py={{ xs: 4, sm: 6 }}
            px={{ xs: 2, sm: 2 }}
          >
            <Stack spacing={4}>
              <FormControl fullWidth >
              <TextField
                label="Full Name"
                variant="outlined"
                placeholder="Enter Your Full Name"
                {...(errors.full_name ? { error: true, helperText: errors.full_name.message } : {})}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" sx={{ mx: 1 }}>
                      <PersonOutlineOutlined sx={{ fontSize: iconSize }} />
                    </InputAdornment>
                  ),
                }}
                {...register("full_name", { required: "Full Name Required" })}
                type="text"
                sx={{ fontSize: inputFontSize,height:inputHeight }}
              />
              </FormControl>
             
              <TextField
                label="Phone"
                variant="outlined"
                {...(errors.phone || status.error ? { error: true } : {})}
                helperText={ errors.phone?(errors.phone.message):(status.error)}   
                             placeholder="Enter Your Phone"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" sx={{ mx: 1 }}>
                      <LocalPhoneOutlinedIcon sx={{ fontSize: iconSize }} />
                    </InputAdornment>
                  ),
                }}
                {...register("phone", { required: "Phone Required" })}
                type="tel"
                sx={{ fontSize: inputFontSize,height:inputHeight }}
              />

              <Controller
                name="gender"
                control={control}
                defaultValue=""
                rules={{ required: "Gender Required" }}

                render={({ field }) => (
                  <TextField
                    {...field}
                    select
                    label="Gender"

                    variant="outlined"
                    {...(errors.gender ? { error: true, helperText: errors.gender.message } : {})}

                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start" sx={{ mx: 1 }}>
                          <Wc sx={{ fontSize: iconSize }} />
                        </InputAdornment>
                      ),
                    }}
                    sx={{ fontSize: inputFontSize,height:inputHeight }}

                    onChange={(e) => {
                      const selectedValue = e.target.value;
                      field.onChange(selectedValue); // Update the field value
                      setValue("gender", selectedValue); // Update the form value
                      trigger("gender"); // Trigger validation
                    }}
                  >
                    <MenuItem value="" disabled selected>Select Gender</MenuItem>
                    <MenuItem value="MALE">Male</MenuItem>
                    <MenuItem value="FEMALE">Female</MenuItem>
                  </TextField>
                  
                )}
              />

              <TextField
                label="Password"
                variant="outlined"
                {...(errors.password ? { error: true, helperText: errors.password.message } : {})}
                placeholder="Enter Your Password"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" sx={{ mx: 1 }}>
                      <HttpsOutlinedIcon sx={{ fontSize: iconSize }} />
                    </InputAdornment>
                  ),
                }}
                {...register("password", { required: "Password Required" })}
                type="password"
                sx={{ fontSize: inputFontSize,height:inputHeight }}
              />

              {/* {status.serverErrors && (
                <Alert severity="error">
                  {Object.values(status.serverErrors).map((error, index) => (
                    <React.Fragment key={index}>
                      - {error}
                      <br />
                    </React.Fragment>
                  ))}
                </Alert>
              )} */}

              <Button
                type="submit"
                variant="contained"
                size="large"
                fullWidth
                sx={{
                  backgroundColor: "#0f0e58",
                  ":hover": { backgroundColor: "#0f0e78" },
                }}
                disabled={status.loading }
              >
                {status.loading ? (
                  <CircularProgress sx={{color:"#0f0e58"}} size={25} />
                ) : (
                  "Sign Up"
                )}
              </Button>
              <Typography>
                <Link to="/login" style={{textDecoration:"none",pointerEvents: status.loading ? "none" : "auto",color:"#0f0e58",fontFamily: 'Palanquin'}} >Back to Login</Link>
              </Typography>
            </Stack>
          </Box>
        </Box>
      </Box>
    </form>
  );
}
