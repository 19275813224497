import React  from "react";
import {
  Box,
  Typography,
  Stack,
  FormControl,
  Button,
  InputAdornment,
  TextField,
  CircularProgress,
} from "@mui/material";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import { Link } from "react-router-dom";
import { iconSize, inputFontSize, inputHeight } from "../../constants/Constant";

export default function ChangePhoneForm({
  register,
  handleSubmit,
  onSubmit,
  status,
  formState: { errors },
  onBack,
}) {
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box width={{ display: "flex", justifyContent: "center" }}>
        <Box
          width={{ xs: "92%", sm: "100%" }}
          bgcolor="white"
          color="#1c2145"
          borderRadius={2}
        >
          <Box
            width={{ xs: "90%", sm: "80%" }}
            mx={"auto"}
            py={{ xs: 4, sm: 8 }}
            px={{ xs: 2, sm: 2 }}
          >
            <Stack spacing={4}>
              <Typography
                align="center"
                variant="h5"
                sx={{ fontWeight: "bold" }}
              >
                Change Your Phone
              </Typography>

              <FormControl fullWidth sx={{ height: "52px" }}>
                <TextField
                  label="New Phone"
                  variant="outlined"
                  placeholder="Enter Your New Phone"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" sx={{ mx: 1 }}>
                        <LocalPhoneOutlinedIcon sx={{ fontSize: iconSize }} />
                      </InputAdornment>
                    ),
                  }}
                  {...register("phone", { required: "Phone Required" })}
                  {...(errors.phone
                    ? { error: true, helperText: errors.phone.message }
                    : {})}
                  type="tel"
                  sx={{ fontSize: inputFontSize, height: inputHeight }}
                />
              </FormControl>

              <Button
                type="submit"
                variant="contained"
                size="large"
                fullWidth
                sx={{
                  backgroundColor: "#12c63f",
                  ":hover": { backgroundColor: "#12c658" },
                }}
                disabled={status.loading}
              >
                {status.loading ? (
                  <CircularProgress sx={{ color: "#0f0e58" }} size={25} />
                ) : (
                  "Change Phone"
                )}
              </Button>
              <Typography>
                <Link
                  onClick={onBack}
                  style={{
                    textDecoration: "none",
                    pointerEvents: status.loading ? "none" : "auto",
                    color: "#0f0e58",
                    fontFamily: "Palanquin",
                  }}
                >
                  Back to Prev.
                </Link>
              </Typography>
            </Stack>
          </Box>
        </Box>
      </Box>
    </form>
  );
}
