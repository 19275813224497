import Login from "../pages/auth/Login";
import Register from "../pages/auth/Register";
import VerifyOTP from "../pages/auth/VerifyOTP";
import VerifyID from "../pages/auth/VerifyID";
import ForgotPassword from "../pages/auth/ForgotPassword";
import UnderReview from "../pages/auth/UnderReview";
import UploadID from "../pages/auth/UploadID";
import NotFound from "../pages/NotFound";
import Profile from "../pages/auth/Profile";
import VerifyPhone from "../pages/auth/VerifyPhone";
import PrivateRoute from "../components/ProtectedRoute";

const routes = [
 
  {
    path: "/login",
    index:true,
    element: <Login />,
  },
  {
    path: "/register",
    element: <Register />,
  },
  {
    path: "forgot-password",
    element: <ForgotPassword />,
  },

  {
    path: "/",
    element: <PrivateRoute />,
    children: [
      {
        path: "verify-phone",
        element: <VerifyPhone />,
      },
      {
        path: "verify-otp",
        element: <VerifyOTP />,
      },
      {
        path: "verify-id",
        element: <VerifyID />,
      },

      
      {
        path: "under-review",
        element: <UnderReview />,
      },
      {
        path: "upload-id",
        element: <UploadID />,
      },

      {
        path: "profile",
        element: <Profile />,
      },
    ],
  },

  {
    path: "*",
    element: <NotFound />,
  },
];

export default routes;
