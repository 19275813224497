import { Box, Typography } from '@mui/material'
import React, { useContext } from 'react'
import { AuthContext } from '../../contexts/AuthContext'

export default function AuthHeader() {
  const {user}=useContext(AuthContext);
  return (
    <Box>
    
    {
      user.name?(
        <><Typography align="center" noWrap={false} variant="h4" sx={{ fontWeight: "bold",marginX:"20px" }}>
            Selam
            <span
              style={{
                color: "green",
                height: "8px",
                width: "8px",
                display: "inline-block",
                marginRight: "2px",
              }}
            >
              ,
            </span>
          {user.name}
          </Typography>
          <Typography align="center" variant="subtitle1">
              welcome!
            </Typography>
            </>
      ):(
    <Typography align="center" color={"#0f0e58"} variant="h3" sx={{ fontWeight:"bolder" }}>
    Student
    <span
      style={{
        color: "green",
        height: "8px",
        width: "8px",
        display: "inline-block",
        margin:"1px"
      }}
    >.</span>
    et
  </Typography>)}
  </Box>
  )
}
