import React, { useContext, useState } from "react";
import AppLayout from "../../components/layouts/AppLayout";
import VerifyPhoneForm from "../../components/auth/VerifyPhoneForm";
import { useForm } from "react-hook-form";
import { Box, Stack } from "@mui/material";
import { AuthContext } from "../../contexts/AuthContext";
import { baseUrl } from "../../constants/Constant";
import axios from "axios";
import ChangePhoneForm from "../../components/auth/ChangePhone";
import AuthHeader from "../../components/auth/AuthHeader";

export default function VerifyPhone() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm();
  const { user, setUser } = useContext(AuthContext);
  const [step, setStep] = useState(1);
  const [status, setStatus] = useState({
    resending: false,
    verifying: false,
    error: null,
  });

  const loggedInToken = localStorage.getItem("token");
  const config = {
    headers: {
      Authorization: `Bearer ${loggedInToken}`,
    },
  };

  const onChangePhone = () => {
    setStep(2);
  };

  const onBack = () => {
    setStep(1);
  };

  const onChangePhoneSubmit =async (data) => {
    const url = baseUrl + "student/change-phone";
    setStatus({ ...status, loading: true });

    try {
      const response = await axios.post(url, data, config);

      if (response.data.status === true) {
        const userInfo = response.data.userInfo;
        setUser({...userInfo,isAuth:true});
        setStatus({ ...status, loading: false, error: null });
        setStep(1);
      }
    } catch (error) {
      const msg = error.response?.data.message.phone;
      if (msg) {
        setError("phone", {
          type: "custom",
          message: msg,
        });
      }

      setStatus({
        ...status,
        loading: false,
      });
    }
  };

  const onSubmit = async (data) => {
    data.phone = user.phone;

    const url = baseUrl + "student/verify-phone";
    setStatus({ ...status, verifying: true });

    try {
      const response = await axios.post(url, data, config);

      if (response.data.status === true) {
        const userInfo = response.data.userInfo;
        setUser({...userInfo,isAuth:true});
        setStatus({ ...status, verifying: false, error: null });
      }
    } catch (error) {
      const msg = error.response?.data.message;
      console.log(msg);
      if (msg) {
        setError("otp", {
          type: "custom",
          message: msg,
        });
      }

      setStatus({
        ...status,
        verifying: false,
      });
    }
  };

  const onResend = async () => {
    const url = baseUrl + "student/resend-otp";
    setStatus({ ...status, resending: true });

    try {
      const response = await axios.get(url, config);
      console.log(response.data.otp);

      if (response.data.status === true) {
      }
    } catch (error) {
      console.log(error.response?.data.message);
    }
    setStatus({ ...status, resending: false });
  };

  return (
    <>
      <AppLayout>
        <Box width={{ xs: "100%", sm: 500 }}>
          <Stack direction="column" spacing={1}>
            
          <AuthHeader/>

            {step === 1 ? (
              <VerifyPhoneForm
                register={register}
                handleSubmit={handleSubmit}
                onSubmit={onSubmit}
                status={status}
                onResend={onResend}
                onChangePhone={onChangePhone}
                formState={{ errors }}
              />
            ) : (
              <ChangePhoneForm
                register={register}
                handleSubmit={handleSubmit}
                onSubmit={onChangePhoneSubmit}
                status={status}
                formState={{ errors }}
                onBack={onBack}
              />
            )}
          </Stack>
        </Box>
      </AppLayout>
    </>
  );
}
