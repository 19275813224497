import React from "react";
import {
  Box,
  Typography,
  Stack,
  FormControl,
  Button,
  InputAdornment,
  CircularProgress,
  TextField,
} from "@mui/material";
import { Link } from "react-router-dom";
import { Password } from "@mui/icons-material";
import { inputFontSize, inputHeight } from "../../constants/Constant";

export default function VerifyOTPForm({
  register,
  handleSubmit,
  onVerifyOTP,
  status,
  onResendOTP,
  formstate: { errors },
}) {
  return (
    <form onSubmit={handleSubmit(onVerifyOTP)}>
      <Typography align="center" variant="subtitle1">
        Verify Your OTP
      </Typography>
      <Box width={{ display: "flex", justifyContent: "center" }}>
        <Box
          width={{ xs: "92%", sm: "100%" }}
          bgcolor="white"
          color="#1c2145"
          borderRadius={2}
        >
          <Box
            width={{ xs: "90%", sm: "80%" }}
            mx={"auto"}
            py={{ xs: 4, sm: 8 }}
            px={{ xs: 2, sm: 2 }}
          >
            <Stack spacing={4}>
              <FormControl fullWidth >
                <TextField
                  placeholder="OTP"
                  {...(errors.otp ? { error: true } : {})}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Password />
                      </InputAdornment>
                    ),
                  }}
                  {...register("otp", { required: "OTP Required" })}
                  helperText={errors.otp ? errors.otp.message : null}
                  type="text"
                  sx={{ fontSize: inputFontSize ,height:inputHeight}}
                />
              </FormControl>

              <Button
                type="submit"
                variant="contained"
                size="large"
                fullWidth
                sx={{
                  backgroundColor: "#12c63f",
                  ":hover": { backgroundColor: "#12c658" },
                }}
                disabled={status.sending || status.loading}
              >
              {status.loading ? (
                <CircularProgress sx={{color:"#0f0e58"}} size={25} />
              )  : (
                  "Verify"
                )}
              </Button>
              <Typography>
                {status.sending ? (
                  <span> Resending...</span>
                ) : (
                  <Link onClick={onResendOTP}>Resend OTP</Link>
                )}
              </Typography>
            </Stack>
          </Box>
        </Box>
      </Box>
    </form>
  );
}
