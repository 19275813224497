import React from "react";
import {
  Box,
  Typography,
  Stack,
  FormControl,
  Button,
  InputAdornment,
  CircularProgress,
  TextField,
} from "@mui/material";
import { Link } from "react-router-dom";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import { iconSize, inputFontSize } from "../../constants/Constant";

export default function ForgotPasswordForm({register,handleSubmit,onSendOTP,status,formState:{errors}}) {
 

  return (
   

        <form onSubmit={handleSubmit(onSendOTP)}>
           <Typography align="center" variant="subtitle1">
Forgot Your Password
            </Typography>
          <Box width={{ display: "flex", justifyContent: "center" }}>
        <Box width={{ xs: "92%", sm: "100%" }} bgcolor="white" color="#1c2145"   borderRadius={2}>
          <Box width={{ xs: "90%", sm: "80%" }} mx={"auto"} py={{xs:4,sm:8}} px={{xs:2,sm:2}}>
              <Stack spacing={4}>
                {/* <Typography align="center" variant="h5" sx={{ fontWeight: "bold" }}>Reset Your Password</Typography> */}
          
              
                <FormControl fullWidth >
              <TextField  label="Phone" variant="outlined"
                                placeholder="Enter Your Phone "

              InputProps={{
                startAdornment:
                  <InputAdornment position="start" sx={{ mx: 1 }}>
                    <LocalPhoneOutlinedIcon sx={{ fontSize:iconSize}}  />
                  </InputAdornment>
                
              }}
            
              {...register("phone", { required: "Phone Required" })}
              {...(errors.phone ? { error: true } : {})}
              helperText={ errors.phone?errors.phone.message:null}
              type="tel"
              sx={{fontSize:inputFontSize}} />
              </FormControl>

                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  fullWidth
                  sx={{
                    backgroundColor: "#0f0e58",
                    ":hover": { backgroundColor: "#0f0e78" },
                  }}
                  disabled={status.sending }
                >
                  {status.sending ? (
                    <CircularProgress sx={{color:"#0f0e58"}}  size={25} />
                  ): (
                  "Send OTP"
                )}
                  
                </Button>
                <Typography>
                <Link to="/login" style={{textDecoration:"none",pointerEvents: status.sending ? "none" : "auto",color:"#0f0e58",fontFamily: 'Palanquin' }}>Back to Login</Link>
              </Typography>
              </Stack>
            </Box>
          </Box>
          </Box>
        </form>
  
  );
}
