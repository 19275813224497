import React, { useContext, useState } from "react";

import AppLayout from "../../components/layouts/AppLayout";
import LoginForm from "../../components/auth/LoginForm";
import { useForm } from "react-hook-form";
import { Box, Stack, Typography } from "@mui/material";
import axios from "axios";
import { baseUrl } from "../../constants/Constant";
import { AuthContext } from "../../contexts/AuthContext";
import AuthHeader from "../../components/auth/AuthHeader";

export default function Login() {
  const { register, handleSubmit,setError, formState: { errors } } = useForm();
  const [status, setStatus] = useState({ error: null, loading: false });
  const { login } = useContext(AuthContext);

  const onSubmit = async (inputData) => {
    const url = baseUrl + "student/login";
    setStatus({ ...status, loading: true });

    try {
      const response = await axios.post(url, inputData);
      if (response?.data.status === true) {


        const userInfo = response.data.userInfo;
        const token = response.data.token;
        login({ userInfo, token });

      } 
    } catch (error) {
      console.log(error.message);
      const msg=error.response?.data.message;
    
      if(msg ){
        setError('phone',{
          type: "manual",
          message: msg,
        });
      }
      setStatus({ ...status, error:error.response?.data.message,loading: false  });

    }

  };



  return (
    <>
      <AppLayout>
        <Box width={{ xs: "100%", sm: 500 }}>
          <Stack direction="column" spacing={1}  >
            <AuthHeader/>
     
            <Typography align="center" variant="subtitle1">
              Login to Your Student.et Account
            </Typography>
              <LoginForm
                register={register}
                handleSubmit={handleSubmit}
                onSubmit={onSubmit}
                status={status}
                formState={{errors}}
              />
          
          </Stack>
        </Box>
      </AppLayout>
    </>
  );
}
