import React, { useContext, useState } from "react";
import AppLayout from "../../components/layouts/AppLayout";
import { Box, Stack } from "@mui/material";
import UploadIDForm from "../../components/auth/UploadIDForm";
import { baseUrl } from "../../constants/Constant";
import axios from "axios";
import { AuthContext } from "../../contexts/AuthContext";
import AuthHeader from "../../components/auth/AuthHeader";

export default function UploadID() {
  const [status, setStatus] = useState({ serverErrors: null, loading: false });
  const { setUser } = useContext(AuthContext);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageErrors, setImageErrors] = useState([]);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    const allowedTypes = ["image/jpeg", "image/png", "image/gif"]; // Allowed image types
    const maxSize = 5 * 1024 * 1024; // Maximum file size (5MB)

    if (file) {
      // Validate file type
      if (!allowedTypes.includes(file.type)) {
        setImageErrors([
          "Invalid file type. Please select a JPEG, PNG, or GIF image.",
        ]);
        setSelectedImage(null);
        return;
      }

      // Validate file size
      if (file.size > maxSize) {
        setImageErrors(["File size exceeds the maximum limit of 5MB."]);
        setSelectedImage(null);
        return;
      }

      // Clear any previous errors
      setImageErrors([]);

      // Set selected image
      setSelectedImage(file);
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (imageErrors.length > 0) {
      return;
    }
    if (!selectedImage) {
      setImageErrors(["Document Required"]);
      return;
    }
    const formData = new FormData();
    formData.append("file_path", selectedImage); // Access the first file in the FileList

    setStatus({ ...status, loading: true });

    try {
      const url = baseUrl + "student/upload-id";
      const token = localStorage.getItem("token");

      const headers = {
        headers: {
          "Content-Type": "multipart/form-data", // Set Content-Type header
          Authorization: `Bearer ${token}`, // Set Authorization header
        },
      };
      const response = await axios.post(url, formData, headers);
      const userInfo = response.data.userInfo;
      setUser({ ...userInfo, isAuth: true });
      setStatus({ ...status, loading: false });
    } catch (error) {
      const msg = error.response?.data.message;
      console.log(msg);
      if (msg) {
        setImageErrors([msg]);
      }
      setStatus({ ...status, loading: false });
    }
  };

  return (
    <>
      <AppLayout>
        <Box width={{ xs: "100%", sm: 500 }}>
          <Stack direction="column" spacing={1}>
            <AuthHeader />

            <UploadIDForm
              onSubmit={onSubmit}
              status={status}
              selectedImage={selectedImage}
              setSelectedImage={setSelectedImage}
              imageErrors={imageErrors}
              setImageErrors={setImageErrors}
              handleImageChange={handleImageChange}
            />
          </Stack>
        </Box>
      </AppLayout>
    </>
  );
}
